<template>
  <SideBar @toggle="$emit('toggle')" class="is-large" ref="sideBar">
    <DoodleGeneralForm title="Unlock Account"
                       in-progress-message="JDoodle is trying to send you unlock link, Please wait..."
                        success-message="Please check your mail for unlock link. (JDoodle sents mail only if the entered mail is already registered)."
                        action-text="Send Unlock Link"
                       :form="doodleForm"
                       :inProgress="doodleForm.inProgress" :completed="doodleForm.completed"
                       :errorMessageText="doodleForm.errorMessage"
                       @action="formAction"
                       @close="$refs.sideBar.emitToggle()">
        <DoodleInput field-name="email" label-text="Email" type="email"
                     left-icon="address-card"
                     rule="required|email"
                     v-model="doodleForm.email" place-holder="Email"/>
    </DoodleGeneralForm>
  </SideBar>
</template>

<script>
import formMixin from '../assets/javascript/form-mixin'
import DoodleGeneralForm from './Utils/DoodleGeneralForm'
import DoodleInput from './Utils/DoodleInput'
import SideBar from './Utils/SideBar'

export default {
  name: 'unlockAccount',
  components: { SideBar, DoodleInput, DoodleGeneralForm },
  inject: ['$validator'],
  mixins: [formMixin],
  data: function () {
    return {
      doodleForm: {
        email: null
      }
    }
  },
  methods: {
    formAction () {
      this.executeAPI({
        url: '/api/register/unlockAccount',
        data: { 'username': this.doodleForm.email },
        method: 'post',
        markCompleted: true,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GLOBAL,
        jdaEvent: 'unlock-account',
        jdaLabel: ''
      })
    }
  }
}
</script>
